$(document).ready(function() {

	var bin = $('#featured-artists__bin');
	
	if(bin.length) {

		var show = $('#featured-artists__show');
		var showCount = show.data('count');
		var binCount = bin.children().length;
		var interval;

		window.addEventListener('focus', function() {
		    
			interval = setInterval(swap, 2000);

		},false);

		window.addEventListener('blur', function() {
		    
			clearInterval(interval);

		},false);

		function swap() {

			var i = Math.round((Math.random() * (binCount - 1)));
			var j = Math.round((Math.random() * (showCount - 1)));

			var add = bin.children().eq(i);
			var removeContainer = show.children().eq(j).find('.featured-artists__show__artist');
			var remove = removeContainer.find('a');

			removeContainer.prepend(add);

			removeContainer.addClass('featured-artists__show__artist--swap');

			removeContainer.one('transitionend', function() {

				console.log('transitionend');

			 	bin.append(remove);
			 	removeContainer.removeClass('featured-artists__show__artist--swap');
			});

		}

		interval = setInterval(swap, 2000);
	}
});